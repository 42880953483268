<template>
    <div>
        <b-container>
            <section class="admin">
                <div class="sector">
                    <AdminNavigation />
                    <h2 class="main-title">Manage users</h2>
                    <div v-if="!getLogsPending" class="mb-3 mt-3">
                        <Spinner size="medium" line-fg-color="#e91e63" />
                    </div>
                    <div v-else class="admin__box">
                        <div class="admin__table">
                            <div class="input-box">
                                <div v-if="filterLogs" @click.prevent="clearFilter" class="clear-icon clear-icon--filter">
                                    <simple-line-icons icon="close" size="small" color="#757575" />
                                </div>
                                <input v-model="filterLogs" class="custom-search-bar" type="text" placeholder="Filter" />
                            </div>
                            <b-table
                                hover
                                id="pendingLogs"
                                :items="getSortedAdminLogs"
                                :fields="fields"
                                :per-page="perPage"
                                :current-page="Number(currentPage)"
                                :filter="filterLogs"
                                responsive>
                                <template slot="user" slot-scope="data">
                                    <span><button v-if="data.item.recent" @click.prevent="setAsSeen(data.item.id)" class="recent-button"><simple-line-icons icon="fire" size="small" color="#ff382b" /></button> {{ data.item.user }}</span>
                                </template>
                                <template slot="type" slot-scope="data">
                                    <span class="profile__status profile__status--negative" v-if="data.item.type == 1">Account deleted</span>
                                    <span class="profile__status profile__status--positive" v-else-if="data.item.type == 2">Charge succeeded</span>
                                    <span class="profile__status profile__status--failed" v-else-if="data.item.type == 3">Charge failed</span>
                                    <span class="profile__status profile__status--cancelled" v-else-if="data.item.type == 4">Subscription cancelled</span>
                                </template>
                            </b-table>
                            <b-pagination
                                @change="scrollToTop('pendingLogs');"
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                                aria-controls="pendingLogs"
                            ></b-pagination>
                            <label class="mr-2" for="paginationInput">Go to page:</label><b-form-input class="pagination__input" id="paginationInput" v-model="currentPage"></b-form-input>
                        </div>
                    </div>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import AdminNavigation from '@/components/AdminNavigation'
import MoveTo from 'moveto'
export default {
  components: {
    SimpleLineIcons,
    Spinner,
    AdminNavigation
  },
  data: function () {
    return {
      perPage: 10,
      currentPage: '1',
      filterLogs: '',
      fields: [
        { key: 'user', label: 'Uid', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'date', label: 'Date', sortable: true }
      ]
    }
  },
  methods: {
    clearFilter () {
      this.filterLogs = ''
    },
    scrollToTop (element) {
      const moveTo = new MoveTo({
        tolerance: 75,
        duration: 1000
      })

      const target = document.getElementById(element)

      moveTo.move(target)
    },
    setAsSeen (id) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to update this log as seen?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#13b81b',
        cancelButtonColor: '#f1352e',
        focusCancel: false
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('setAsSeen', { id })
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'getLogsPending',
      'getLogs'
    ]),
    getSortedAdminLogs () {
      var sorted = this.getLogs.map(function (log) {
        return {
          id: log.id,
          user: log.user,
          date: log.date,
          type: log.type,
          recent: log.recent
        }
      })

      return sorted.reverse()
    },
    rows () {
      return this.getSortedAdminLogs.length
    }
  },
  beforeCreate () {
    this.$store.dispatch('getLogs')
  }
}
</script>
